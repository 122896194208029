const text = {
  summaryInformation: 'Summary Information',
  hospitalityNumber: 'Hospital Number (HN)',
  firstname: 'Firstname',
  lastname: 'Lastname',
  gender: 'Gender',
  male: 'Male',
  female: 'Female',
  dateOfBirth: 'Date of Birth',
  phone: 'Phone',
  email: 'Email',
  register: 'Register',
  note: 'Note',
  back: 'back',
  sorry: 'Sorry!!',
}
export default text
